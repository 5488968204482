export const commands = [
  {
    command: 'presentation',
    responseType: 'list',
    value: ['La commande a bien été effectuée !'],
  },
  {
    command: 'home',
    responseType: 'list',
    value: ['La commande a bien été effectuée !'],
  },
  {
    command: 'experiences',
    responseType: 'list',
    value: ['La commande a bien été effectuée !'],
  },
  {
    command: 'skills',
    responseType: 'list',
    value: ['La commande a bien été effectuée !'],
  },
  {
    command: 'projects',
    responseType: 'list',
    value: ['La commande a bien été effectuée !'],
  },
  {
    command: 'passions',
    responseType: 'list',
    value: ['La commande a bien été effectuée !'],
  },
  {
    command: 'notgeek',
    responseType: 'list',
    value: ['Eh oui, on ne va pas refaire la roue.'],
  },
];
